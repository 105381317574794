<template>
  <div class="page">
    <van-nav-bar
      fixed
      :title="pageTitle"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="plan-detail">
      <van-cell-group class="plan-info">
        <van-collapse v-model="activeNames">
          <van-collapse-item title="预案信息" name="1">
            <van-cell title="预案名称" :value="planningVO.name" />
            <van-cell title="编制单位" :value="planningVO.orgName" />
            <van-cell title="所属部门" :value="planningVO.departmentName" />
            <van-cell title="预案级别" :value="planningVO.levelName" />
            <van-cell title="预案类型" :value="planningVO.typeName" />
            <van-cell title="发布日期" :value="planningVO.publishDate" />
            <van-cell
              title="事故类型"
              :value="planningVO.accidentTypeNameList"
            />
            <van-cell title="备注" :value="planningVO.note" />
          </van-collapse-item>
          <van-collapse-item title="备案信息" name="2">
            <van-cell title="备案情况" :value="planningVO.recordStatusName" />
            <van-cell title="备案单位" :value="planningVO.recordOrgName" />
            <van-cell title="备案编号" :value="planningVO.recordNo" />
            <van-cell title="备案时间" :value="planningVO.recordTime" />
            <van-cell title="备注" :value="planningVO.recordNote" />
          </van-collapse-item>
          <van-collapse-item title="应急组织" name="3">
            <div v-if="orgList && orgList.length">
              <van-cell
                v-for="i in orgList"
                :key="i.id"
                :title="i.name"
                is-link
                @click="goDetail(i)"
              />
            </div>
            <p
              v-else
              style="text-align: center; margin: 20px 0 20px; font-size: 14px;"
            >
              暂无数据
            </p>
          </van-collapse-item>
          <van-collapse-item title="预案内容" name="4">
            <div v-if="stepList && stepList.length">
              <div v-for="(item, i) in stepList" :key="i" class="plan-content">
                <div class="title">{{ `${i + 1}、${item.stepName}` }}</div>
                <div class="content">
                  <span>内容：</span>
                  <span v-html="item.content" />
                </div>
              </div>
            </div>
            <p
              v-else
              style="text-align: center; margin: 20px 0 20px; font-size: 14px;"
            >
              暂无数据
            </p>
          </van-collapse-item>
          <van-collapse-item title="应急疏散图" name="5">
            <div v-if="evacuationFiles && evacuationFiles.length">
              <div
                v-for="item in evacuationFiles"
                :key="item.id"
                class="img-all"
              >
                <van-image width="100%" :src="getImg(item.id)" />
                <div class="img-name">{{ item.name }}</div>
              </div>
            </div>
            <p
              v-else
              style="text-align: center; margin: 20px 0 20px; font-size: 14px;"
            >
              暂无数据
            </p>
          </van-collapse-item>
          <van-collapse-item title="其他附件" name="6">
            <div class="img-all">
              <!-- <div v-for="item in fileDtos" :key="item.id" class="img-detail">
                <van-image width="100%" :src="getImg(item.id)" />
                <div class="img-name">{{ item.name }}</div>
              </div> -->
              <div v-if="fileDtos && fileDtos.length">
                <!-- <van-field
                  :readonly="true"
                  v-bind="fieldProps"
                  name="rescueFile"
                  input-align="left"
                  error-message-align="left"
                >
                  <template #input> -->
                <div v-for="(item, index) in fileDtos" :key="index">
                  <FileUploader
                    v-model="fileDataDtos[index]"
                    :max-count="5"
                    :unable-upload="true"
                    :accept="FILE_ACCEPT_TYPES.FILE"
                  />
                </div>
                <!-- </template> -->
                <!-- </van-field> -->
              </div>
              <p
                v-else
                style="text-align: center; margin: 20px 0 20px; font-size: 14px;"
              >
                暂无数据
              </p>
            </div>
          </van-collapse-item>
          <van-collapse-item title="审批履历" name="7">
            <div v-if="planningApproveVOList && planningApproveVOList.length">
              <div
                v-for="(item, i) in planningApproveVOList"
                :key="item.verifier + i"
              >
                <div class="plan-content">
                  <div>
                    <span class="apl-title">申请人:</span>
                    <span class="apl-value" v-text="item.verifierName" />
                  </div>
                  <div>
                    <span class="apl-title">申请时间:</span>
                    <span class="apl-value" v-text="item.verifyTime" />
                  </div>
                </div>
                <div class="plan-content">
                  <div>
                    <span class="apl-title">审批人:</span>
                    <span class="apl-value" v-text="item.verifierName" />
                  </div>
                  <div>
                    <span class="apl-title">审批时间:</span>
                    <span class="apl-value" v-text="item.verifyTime" />
                  </div>
                  <div>
                    <span class="apl-title">审批意见:</span>
                    <span class="apl-value" v-text="item.passStatus" />
                  </div>
                  <div>
                    <span class="apl-title">备注:</span>
                    <span class="apl-value" v-text="item.note" />
                  </div>
                </div>
              </div>
            </div>
            <p
              v-else
              style="text-align: center; margin: 20px 0 20px; font-size: 14px;"
            >
              暂无数据
            </p>
          </van-collapse-item>
        </van-collapse>
      </van-cell-group>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import { planningsDetail } from "@/api/urgent";
import { FILE_ACCEPT_TYPES } from "@/components/fileuploader/util";
import FileUploader from "@/components/fileuploader/FileUploader";
// import RadioEditField from "@/components/edit/RadioEditField";
const headUrl = window.globalConfig.VUE_APP_FILE_URL;
export default {
  name: "AlarmDetail",
  components: { FileUploader },
  data() {
    return {
      planningVO: {
        name: "",
        orgName: "",
        departmentName: "",
        levelName: "",
        typeName: "",
        publishDate: "",
        accidentTypeNameList: "",
        recordStatusName: "",
        recordOrgName: "",
        recordNo: "",
        recordTime: "",
        recordNote: ""
      },
      fieldProps: {
        border: true,
        placeholder: "请输入",
        clearable: true,
        "input-align": "right",
        "error-message-align": "right"
      },
      FILE_ACCEPT_TYPES,
      orgList: [],
      stepList: [],
      evacuationFiles: [],
      fileDtos: [],
      fileDataDtos: [],
      planningApproveVOList: [],
      pageTitle: "应急预案",
      activeNames: ["1"]
    };
  },
  mounted() {
    this.getAlarmInfoById(this.$route.params.id);
    const temp = sessionStorage.getItem("activeNamesArr");
    this.activeNames = temp && temp.split(",") ? temp.split(",") : ["1"];
  },
  computed: {},
  methods: {
    ...mapActions(["getMessageCountNoticesAction"]),
    getImg(id) {
      // const url = `http://file-fat.tsingyun.net/file/download?downloadFlag=false&fileName=${id}`;
      const url = `${headUrl}/file/download?downloadFlag=false&fileName=${id}`;
      return url;
    },
    getMoreOtherImg() {
      this.fileDataDtos = [];
      this.fileDtos &&
        this.fileDtos.map(ele => {
          this.fileDataDtos.push({
            border: true,
            placeholder: "请输入",
            clearable: true,
            "input-align": "right",
            "error-message-align": "right",
            ...ele
          });
        });
    },
    getAlarmInfoById(id) {
      planningsDetail({ id }).then(data => {
        const {
          planningVO,
          orgRoleUserVOList,
          planningStepVOList,
          planningApproveVOList,
          evacuationFiles,
          fileDtos
        } = data;
        planningVO.accidentTypeNameList = planningVO.accidentTypeNameList.join(
          ","
        );
        this.planningVO = { ...planningVO };
        this.orgList = orgRoleUserVOList;
        this.stepList = planningStepVOList;
        this.planningApproveVOList = planningApproveVOList;
        this.evacuationFiles = evacuationFiles;
        this.fileDtos = fileDtos;
        this.getMoreOtherImg();
      });
    },

    onClickLeft() {
      history.go(-1);
      sessionStorage.setItem("activeNamesArr", []);
    },
    goDetail(data) {
      this.$router.push({
        name: "Staff",
        params: { data },
        query: { id: this.$route.params.id }
      });
      sessionStorage.setItem("activeNamesArr", this.activeNames);
    }
  }
};
</script>

<style scoped lang="scss">
.page {
  text-align: left;
  height: 100vh;
  background: #fff;
}
.plan-detail {
  overflow: auto;
  padding: 58px 0 0;
  // height: calc(100vh - 58px);
  &__tabel {
    display: flex;
    flex-wrap: wrap;
    height: 265px;
    border-radius: 6px;
    border: 1px solid rgba(231, 232, 234, 1);
    font-size: 14px;
    // margin-top: 17px;
    // margin-bottom: 20px;
    margin: 27px 16px 17px;
    overflow: hidden;
  }
  &__tabel--rows {
    width: 170px;
    // height: 52px;
    display: flex;
    border: 1px solid rgba(231, 232, 234, 1);
    border-right: none;
    border-bottom: none;
    box-sizing: border-box;
    &:nth-child(2n + 1) {
      border-left: none;
    }
    &:nth-child(1),
    &:nth-child(2) {
      border-top: none;
    }
    .left {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 67.5px;
      height: inherit;
      text-align: left;
      padding: 10px 5px;
      box-sizing: border-box;
      font-size: 13px;
      font-weight: 400;
      color: #8c8f97;
      line-height: 18px;
    }
    .right {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      flex-wrap: wrap;
      width: 103px;
      height: inherit;
      border-left: 1px solid rgba(231, 232, 234, 1);
      font-size: 13px;
      font-weight: 400;
      color: #3b4664;
      word-break: break-all;
      word-wrap: break-word;
      padding: 9px;
      box-sizing: border-box;
      line-height: 18px;
    }
    .font-color {
      color: #fd687d;
    }
  }
}

.plan-detail ::v-deep {
  .van-tab--active {
    color: #1890ff;
  }
  .van-step__icon--active,
  .van-step__title--active {
    color: #1890ff;
  }
  .van-step--vertical .van-step__circle-container {
    width: 8px;
    height: 8px;
    background: rgba(24, 144, 255, 1);
    box-shadow: 0px 0px 10px rgba(24, 144, 255, 1);
    border-radius: 50%;
  }
  .van-step__circle {
    display: none;
  }
}

.plan-info {
  i.ico-call {
    color: #fd687d;
  }
  .blue-color {
    color: #1676ff;
  }
  .safety-head {
    margin: 0 4px 0 0;
  }
}
::v-deep .van-collapse-item__title--expanded::after {
  display: none;
  border: none;
}
.van-collapse-item--border::after {
  border: none;
  height: 10px;
  width: 100%;
  left: 0;
  right: 0;
  top: -5px;
  background-color: #eceef2;
}
::v-deep .van-collapse-item__content {
  padding: 0 5px;
}
.van-collapse::after,
.van-cell-group::after {
  border: none;
  display: none;
}
::v-deep .van-collapse-item__wrapper .van-cell__title {
  line-height: 30px;
  color: #5d5f63;
  font-size: 14px;
}
.van-cell__value {
  line-height: 30px;
  color: #15171b;
  font-size: 14px;
}
::v-deep .van-cell {
  font-weight: bold !important;
  color: #15171b;
}
.plan-content {
  padding: 10px 12px;
  width: calc(100% - 44px);
  background-color: #f5f7fa;
  margin: 0 10px 10px;
  border-radius: 6px;
  .title {
    margin-bottom: 6px;
    color: #15171b;
    font-weight: bold;
  }
  .content {
    font-size: 14px;
    color: #8c8f97;
    display: flex;
  }
}
.apl-title {
  display: inline-block;
  width: 80px;
}
.apl-value {
  color: #15171b;
}
.img-all {
  margin: 5px 10px 0;
  .img-detail {
    display: inline-block;
    width: calc(33.3% - 10px);
  }
  .img-detail + .img-detail {
    margin-left: 15px;
  }
}
.img-name {
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #2e2e4d;
  margin-top: 5px;
  margin-bottom: 5px;
}
</style>
